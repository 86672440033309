import { useState } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Modal, ButtonDropdown, Button } from "@bitcine/cs-theme"
import { updateChannel, deleteChannel } from "../../redux/channels/api"

const Options = ({ channel, updateChannel, deleteChannel, history }) => {
  const [showModal, setShowModal] = useState(false)
  const editableFields = {
    label: channel.label,
    input_security_group_ip_addresses: channel.input_security_group_ip_addresses,
    input_broadcast_url: channel.input_broadcast_url,
    output_group_url: channel.output_group_url
  }
  const [data, setData] = useState(editableFields)
  return (
    <>
      <ButtonDropdown
        button={{
          className: "link",
          menu: true,
          text: 'settings'
        }}
        dropdown={{
          content: [
            {
              text: "Settings",
              icon: "settings",
              onClick: () => setShowModal(true)
            },
            {
              text: "Delete",
              icon: "delete",
              onClick: () => deleteChannel(channel._id, () => history.push('/channels'))
            }
          ]
        }}/>
      {showModal && <Modal open title="Channel Settings" width={2} onClose={() => setShowModal(false)}>
        <div>
          {
            ["label", "input_broadcast_url", "output_group_url"].map(field => <div key={field} className='mt1'>
              <label className='cs-label capitalize'>{field.replace(/_/g, ' ')}</label>
              <input className='cs-input col-12' value={data[field]} onChange={e => setData({ ...data, [field]: e.target.value })}/>
            </div>)
          }
          <label className='mt1 cs-label'>Comma-separated list of IP addresses that are allowed to broadcast to this channel.</label>
          <textarea
            className='cs-textarea col-12'
            value={data.input_security_group_id_addresses}
            onChange={e => setData({ ...data, input_security_group_ip_addresses: e.target.value })}/>
          <div className='flex justify-end mt2'>
            <Button
              disabled={data === editableFields}
              onClick={() => updateChannel(
                channel._id,
                data,
                () => setShowModal(false)
              )}>
              Update
            </Button>
          </div>
        </div>
      </Modal>}
    </>
  )
}

const mapStateToProps = state => ({
  channel: state.channels.model
})

const mapDispatchToProps = dispatch => ({
  updateChannel: bindActionCreators(updateChannel, dispatch),
  deleteChannel: bindActionCreators(deleteChannel, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Options))