import { 
  GET_CHANNELS,
  GET_CHANNEL,
  CREATE_CHANNEL,
  UPDATE_CHANNEL,
  DELETE_CHANNEL
} from './types'
import { get, put, post, del } from '../fetch'

export const getChannels = () => dispatch => dispatch(
  get(
    GET_CHANNELS.REQUEST,
    `live-channels`,
  )
)

export const createChannel = (label, callback) => dispatch => dispatch(
  post(
    CREATE_CHANNEL.REQUEST,
    `live-channels`,
    {
      label
    },
    callback
  )
)

export const getChannel = channelID => dispatch => dispatch(
  get(
    GET_CHANNEL.REQUEST,
    `live-channels/${channelID}`,
  )
)

export const updateChannel = (channelID, data, callback) => dispatch => dispatch(
  put(
    UPDATE_CHANNEL.REQUEST,
    `live-channels/${channelID}`,
    data,
    callback
  )
)

export const deleteChannel = (channelID, callback) => dispatch => dispatch(
  del(
    DELETE_CHANNEL.REQUEST,
    `live-channels/${channelID}`,
    null,
    callback
  )
)