import { useState, useEffect } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Modal, Table, Button } from "@bitcine/cs-theme"
import { getChannels } from "../../redux/channels/api"
import { playChannelsOnDevices } from "../../redux/devices/api"

const PlayChannelOnDevicesButton = ({ deviceIDs, getChannels, status, channels, playChannelsOnDevices, clearSelectedIDs }) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedChannel, setSelectedChannel] = useState({})
  useEffect(() => {
    if (status === 'PENDING') {
      getChannels()
    }
  }, [getChannels, status])
  return (
    <>
      <Button
        disabled={deviceIDs.length === 0}
        onClick={() => setShowModal(true)}>
        Play Channel
      </Button>
      {showModal && <Modal open width={2} title="Play Channel" onClose={() => setShowModal(false)}>
        <div> 
          <p>Select which channel you would like to play on the selected device{deviceIDs.length === 1 ? '' : 's'}.</p>
          <Table
            className='my3'
            widths={["auto", 200]}
            header={{
              columns: [
                { text: "Label" },
                { text: "Status" },
              ],
            }}
            body={{
              data: channels,
              row: {
                compact: true,
                checkbox: {
                  checked: (data) => selectedChannel._id === data._id,
                  onChange: (data) => setSelectedChannel(data),
                },
                onClick: (e, data) => setSelectedChannel(data),
                render: [
                  data => data.label,
                  data => data.status,
                ]
              },
            }}/>
          <div className='flex items-center justify-end mt2'>
            <Button
              onClick={() => {
                playChannelsOnDevices([selectedChannel._id], deviceIDs)
                setShowModal(false)
                clearSelectedIDs()
              }}>
              Play {selectedChannel.label} on {deviceIDs.length} device{deviceIDs.length === 1 ? '' : 's'}
            </Button>
          </div>
        </div>
      </Modal>}
    </>
  )
}

const mapStateToProps = state => ({
  status: state.channels.status,
  channels: state.channels.list
})

const mapDispatchToProps = dispatch => ({
  getChannels: bindActionCreators(getChannels, dispatch),
  playChannelsOnDevices: bindActionCreators(playChannelsOnDevices, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayChannelOnDevicesButton))