import { useEffect, useState } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Status, Table, Button } from "@bitcine/cs-theme"
import { getDevice, playChannelsOnDevices } from "../../redux/devices/api"
import LivestreamPlayer from "../../components/LivestreamPlayer"

const Device = ({ match, status, device, getDevice, playChannelsOnDevices }) => {
  const [viewChannel, setViewChannel] = useState(false)
  const deviceID = match.params.deviceID
  useEffect(() => {
    const interval = setInterval(() => {
      getDevice(deviceID)
    }, 10000)
    getDevice(deviceID)
    return () => clearInterval(interval);
  }, [getDevice, deviceID])
  return (
    <Status pending={status === "PENDING"}>
      <div className='border border-gray-5 p2 mt2 box-shadow bg-white'>
        <div className='flex items-center justify-between'>
          <h3>{device.name}</h3>
          <Button
            small
            disabled={!device.now_playing_channel}
            onClick={() => setViewChannel(device.now_playing_channel)}>
            View Active Livestream
          </Button>
        </div>
        <div className='border-top border-bottom border-gray-5 my2 py2'>
          <p>Status: <b>{device.status}</b></p>
          {device.now_playing_channel && <p>Now Playing: <b>{device.now_playing_channel.label}</b></p>}
          <p>IP Address: <b>{device.decoder_ip_address}</b></p>
        </div>
        <Table
          className='my3'
          widths={[45, "auto", 200, 200]}
          header={{
            columns: [
              { text: "" },
              { text: "Label" },
              { text: "Status" },
              { text: "" }
            ],
          }}
          body={{
            data: device.channels,
            row: {
              compact: true,
              render: [
                data => data.is_playing
                  ? <span className="green material-icons">live_tv</span>
                  : data.is_attaching || data.is_pending_play
                  ? <span className="muted material-icons" style={{ color: 'darkorange' }}>hourglass_bottom</span>
                  : data.is_attached
                  ? <span className="muted material-icons">pause_presentation</span>
                  : <span className="muted material-icons">remove_circle_outline</span>,
                data => data.label,
                data => data.is_playing
                  ? 'Playing'
                  : data.is_pending_play 
                  ? 'Pending Play'
                  : data.is_attached
                  ? 'Not Playing'
                  : data.is_attaching
                  ? 'Attaching'
                  : 'Not Attached',
                data =>
                  <Button
                    disabled={data.is_playing || data.is_pending_play}
                    link
                    small
                    white
                    onClick={() => playChannelsOnDevices([data._id], [device._id])}>
                    Play Livestream
                  </Button>
              ]
            },
            empty: {
              title: "No Channels Attached",
              text: "Attach channels to this device!",
            }
          }}/>
      </div>
      {viewChannel && <LivestreamPlayer channel={viewChannel} onClose={() => setViewChannel(null)}/>}
    </Status>
  )
}

const mapStateToProps = state => ({
  status: state.devices.status,
  device: state.devices.model
})

const mapDispatchToProps = dispatch => ({
  getDevice: bindActionCreators(getDevice, dispatch),
  playChannelsOnDevices: bindActionCreators(playChannelsOnDevices, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Device)