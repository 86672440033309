import { useState } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Modal, Button } from "@bitcine/cs-theme"
import { createChannel } from "../../redux/channels/api"

const CreateChannelButton = ({ createChannel, history }) => {
  const [showModal, setShowModal] = useState(false)
  const [label, setLabel] = useState("")
  return (
    <>
      <Button onClick={() => setShowModal(true)}>
        Create Channel
      </Button>
      {showModal && <Modal open title="Create New Channel" onClose={() => setShowModal(false)}>
        <div> 
          <p>Enter a descriptive label for this channel then click the button below.</p>
          <input
            className='cs-input col-12'
            placeholder='Enter label here'
            value={label}
            onChange={e => setLabel(e.target.value)}/>
          <div className='flex items-center justify-end mt2'>
            <Button
              onClick={() => createChannel(label, res => {
                setShowModal(false)
                history.push(`channels/${res._id}`)
              })}>Create Channel</Button>
          </div>
        </div>
      </Modal>}
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  createChannel: bindActionCreators(createChannel, dispatch),
})

export default withRouter(connect(null, mapDispatchToProps)(CreateChannelButton))